import Vue from "vue";
import VueRouter from "vue-router";
import {StorageHelper} from "../common/storageHelper";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue")
    },
    {
        path: "/users",
        name: "users",
        alias: "/",
        meta: { requiresAuth: true },
        component: () => import("../views/Users.vue")
    },
    {
        path: "/users/:username/statement/",
        name: "statement",
        meta: { requiresAuth: true },
        component: () => import("../views/Statement.vue")
    },
    {
        path: "/users/:username",
        name: "userDetail",
        meta: { requiresAuth: true },
        component: () => import("../views/UserDetail.vue")
    }
];

const routerManager = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    routes
});

routerManager.beforeEach((to, from, next) => {

    if (to.meta.requiresAuth) {
        if (StorageHelper.getToken()) {
            if (to.path === "/") {
                next({name : "users"})
            } else {
                next();
            }
        } else {
            next({ name: "login" });
        }
    } else {
        if (to.name === "login" && StorageHelper.getToken()) {
            next({name : "users"})

        } else {
            next();
        }
    }
});

export default routerManager;
